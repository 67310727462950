<template>
    <page
        id="sro-editor"
        :title="page_title"
        :back-navigation-fallback="default_back"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
        >
            <div v-if="current_step === 1">
                <div class="columns">
                    <div class="column col-md-12">
                        <form-input
                            id="title"
                            name="title"
                            v-model="edited_record.title"
                            :required="true"
                            :editable="editable('title')"
                            :saving="saving('title')"
                            :error="has_error('title')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Title") }}</template>
                        </form-input>
                        <form-select
                            id="company"
                            name="company"
                            v-model="edited_record.company"
                            data-source="service_repair_order"
                            :show-all="false"
                            :required="true"
                            :editable="editable('company')"
                            :saving="saving('company')"
                            :error="has_error('company')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Company") }}</template>
                        </form-select>
                        <form-select
                            v-if="edited_record.company"
                            id="affiliation_id"
                            name="affiliation_id"
                            v-model="edited_record.affiliation_id"
                            data-source="service_repair_order"
                            :data-source-additional-data="{ company: edited_record.company }"
                            :show-all="false"
                            :required="false"
                            :editable="editable('affiliation_id')"
                            :saving="saving('affiliation_id')"
                            :error="has_error('affiliation_id')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Contact") }}</template>
                        </form-select>
                        <div class="columns">
                            <div class="column col-sm-12">
                                <form-dropdown
                                    id="type_id"
                                    name="type_id"
                                    v-model="edited_record.type_id"
                                    :options="vocabulary('VOCABULARY_SRO_TYPE')"
                                    :required="true"
                                    :editable="editable('type_id')"
                                    :saving="saving('type_id')"
                                    :error="has_error('type_id')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ translate("Type") }}</template>
                                </form-dropdown>
                            </div>
                            <div class="column col-sm-12">
                                <form-dropdown
                                    id="status_id"
                                    name="status_id"
                                    v-model="edited_record.status_id"
                                    :options="vocabulary('VOCABULARY_SRO_STATUS')"
                                    :required="true"
                                    :editable="editable('status_id')"
                                    :saving="saving('status_id')"
                                    :error="has_error('status_id')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ translate("Status") }}</template>
                                </form-dropdown>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column col-sm-12">
                                <form-dropdown
                                    id="class"
                                    name="class"
                                    v-model="edited_record.class"
                                    :options="vocabulary('VOCABULARY_SRO_CLASS')"
                                    id-field="remote_id"
                                    :required="false"
                                    :editable="editable('class')"
                                    :saving="saving('class')"
                                    :error="has_error('class')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ translate("Class") }}</template>
                                </form-dropdown>
                            </div>
                            <div class="column col-sm-12">
                                <form-dropdown
                                    id="issue_priority"
                                    name="issue_priority"
                                    v-model="edited_record.issue_priority"
                                    :options="vocabulary('VOCABULARY_SRO_PRIORITY')"
                                    id-field="remote_id"
                                    :required="false"
                                    :editable="editable('issue_priority')"
                                    :saving="saving('issue_priority')"
                                    :error="has_error('issue_priority')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ translate("Priority") }}</template>
                                </form-dropdown>
                            </div>
                        </div>
                        <form-select
                            id="assignee"
                            name="assignee"
                            v-model="edited_record.assignee"
                            data-source="service_repair_order"
                            :show-all="false"
                            :required="true"
                            :editable="editable('assignee')"
                            :saving="saving('assignee')"
                            :error="has_error('assignee')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Received By") }}</template>
                        </form-select>
                        <form-date-input
                            id="submitted_at"
                            name="submitted_at"
                            v-model="edited_record.submitted_at"
                            :required="false"
                            :editable="editable('submitted_at')"
                            :saving="saving('submitted_at')"
                            :error="has_error('submitted_at')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Received Date") }}</template>
                        </form-date-input>
                        <form-textbox
                            id="description"
                            name="description"
                            v-model="edited_record.description"
                            :required="false"
                            :editable="editable('description')"
                            :saving="saving('description')"
                            :error="has_error('description')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Description") }}</template>
                        </form-textbox>

                        <h5 class="mt-8">
                            <open-icon
                                glyph="paperclip"
                                class="mr-2"
                            />
                            {{ translate("Attachments") }}
                        </h5>
                        <div class="columns my-2 attachments">
                            <div
                                v-for="attachment in attachments"
                                :key="attachment.id"
                                :class="{ 'col-2': attachment_image(attachment), 'col-4': !attachment_image(attachment) }"
                                class="column nibnut-hoverable"
                            >
                                <a
                                    v-if="attachment_image(attachment)"
                                    :href="attachment.url"
                                    target="smm-sro-image-preview"
                                    class="nibnut-aspect-ratio-box nibnut-ratio-square"
                                >
                                    <div
                                        :style="{ backgroundImage: `url('${attachment.url}')` }"
                                    ></div>
                                </a>
                                <div
                                    v-else
                                    class="card"
                                >
                                    <div class="card-body">
                                        <a
                                            :href="attachment.url"
                                            target="smm-sro-image-preview"
                                        >
                                            <open-icon
                                                :glyph="attachment_icon(attachment)"
                                                class="mr-1"
                                            />
                                            {{ attachment.name }}
                                        </a>
                                    </div>
                                </div>
                                <default-button
                                    v-if="holding_mode"
                                    class="s-circle nibnut-document-uploader-clear nibnut-hover-visible"
                                    @click.stop.prevent="clear_attachment(attachment)"
                                >
                                    <open-icon
                                        glyph="times-circle"
                                        :title="translate('Delete file...')"
                                    />
                                </default-button>
                            </div>
                        </div>
                        <upload-input
                            id="attachment"
                            name="attachment"
                            url=""
                            :alt="translate('PDF or image')"
                            :accepts="upload_accepts('attachment')"
                            :uploading="!!uploading.attachment"
                            :uploaded="uploaded.attachment || 0"
                            :show-button="false"
                            :error="has_error('attachment')"
                            @upload="upload_file_list('attachment', $event)"
                            @clear="confirm_clear_file('attachment')"
                        >
                            <template v-slot:empty-title>
                                <slot name="empty-title">{{ translate("Drag your image here or click to select...") }}</slot>
                            </template>
                        </upload-input>
                    </div>
                    <div
                        v-if="custom_fields.length"
                        class="column col-md-12"
                    >
                        <form-accelo-custom-field
                            v-for="field_id in custom_fields"
                            :key="field_id"
                            :field="field_id"
                            :value="custom_field_value(field_id)"
                            :editable="editable(field_id)"
                            :saving="saving(field_id)"
                            :error="has_error(field_id)"
                            @input="save"
                            @create="save"
                        />
                        <div
                            v-if="!!edited_record.tasks && !!edited_record.tasks.length"
                            class="mt-8"
                        >
                            <h5>{{ translate("Work Log") }}</h5>
                            <ul>
                                <li
                                    v-for="task in edited_record.tasks"
                                    :key="task.id"
                                >
                                    {{ task.title }}
                                    <div
                                        v-if="task.assignee"
                                        class="text-small text-gray"
                                    >
                                        {{ task.assignee.firstname }} {{ task.assignee.surname }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="current_step === 2"
                id="terms-and-conditions"
                class="d-flex d-flex-columns"
            >
                <div>
                    <div v-html="setting('sro_terms_of_service')"></div>
                </div>
                <div class="flex-static mt-8">
                    <div class="columns align-items-center">
                        <div class="column flex-variable">
                            <label class="form-label">
                                {{ translate("Signature") }}
                                <sup class="ml-1">*</sup>
                            </label>
                        </div>
                        <div
                            v-if="!!edited_record[current_signature_field]"
                            class="column flex-static"
                        >
                            <default-button
                                flavor="link"
                                color="error"
                                size="sm"
                                @click.prevent="clear_signature"
                            >
                                <open-icon
                                    glyph="backspace"
                                    size="lg"
                                />
                            </default-button>
                        </div>
                    </div>
                    <VueSignaturePad
                        ref="signature_pad"
                        height="15vh"
                        :options="{ onEnd: save_signature }"
                        :custom-style="{ border: `${can_be_signed ? 'black': 'gray'} 3px solid` }"
                    />
                    <div
                        v-if="!!edited_record && !!edited_record.id && !!edited_record.signature_in"
                        class="columns align-items-center mt-8 bg-gray"
                    >
                        <div class="column flex-static">
                            <label class="form-label">
                                {{ translate("Checkin") }}
                                <span v-if="!!edited_record.signed_in_at"> {{ edited_record.signed_in_at | nibnut.date("yyyy/MM/dd") }}</span>
                            </label>
                        </div>
                        <div
                            class="column flex-variable"
                        >
                            <img
                                :src="edited_record.signature_in"
                                class="signature"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!!edited_record"
                class="columns mt-8"
            >
                <div v-if="current_step > 1" class="column">
                    <default-button
                        :disabled="!!creating_record"
                        @click.prevent="step_by(-1)"
                    >
                        <open-icon
                            glyph="angle-left"
                            class="mr-1"
                        />
                        {{ translate("Details") }}
                    </default-button>
                </div>
                <div class="column">
                    <div class="d-flex justify-content-end">
                        <base-link
                            v-if="!!record_id"
                            ref="print_label_button"
                            :href="`/service_repair_order/${edited_record.id}/label`"
                            target="smm-label"
                            class="btn mr-2"
                        >
                            {{ translate("Print Label") }}
                        </base-link>
                        <default-button
                            v-if="current_step <= 1"
                            :disabled="!!creating_record"
                            @click.prevent="step_by(1)"
                        >
                            {{ translate("Signature") }}
                            <open-icon
                                glyph="angle-right"
                                class="ml-1"
                            />
                        </default-button>
                        <default-button
                            v-if="(current_step > 1) || !!edited_record.deleted_at"
                            :disabled="!!creating_record"
                            class="mx-2"
                            @click.prevent="$router.go(-1)"
                        >
                            <span v-if="!!record_id && !!edited_record.deleted_at">{{ translate("Done") }}</span>
                            <span v-else>{{ translate("Cancel") }}</span>
                        </default-button>
                        <div
                            v-if="!record_id && (current_step > 1)"
                            class="mx-2"
                        >
                            <default-button
                                color="primary"
                                :waiting="!!creating_record"
                                :disabled="!!creating_record || !is_saveable"
                                @click.prevent="record_save"
                            >
                                {{ translate("Create") }}
                            </default-button>
                            <form-toggle-input
                                id="print_on_create"
                                name="print_on_create"
                                type="checkbox"
                                v-model="print_on_create"
                                size="sm"
                                :required="false"
                            >
                                {{ translate('Print Label') }}
                            </form-toggle-input>
                        </div>
                        <default-button
                            v-else-if="!!record_id && !edited_record.deleted_at &&  (current_step > 1)"
                            :waiting="!!checking_out"
                            :disabled="!!checking_out"
                            class="mx-2"
                            @click.prevent="checkout"
                        >
                            {{ translate("Check Out") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>

        <modal-dialog
            id="print-prompt"
            :show.sync="print_prompt"
            class="text-center"
        >
            <base-link
                v-if="!!edited_record"
                ref="print_label_button"
                :href="`/service_repair_order/${edited_record.id}/label`"
                target="smm-label"
                class="btn btn-block mr-2"
                @click="print_prompt = false"
            >
                {{ translate("Print Label") }}
            </base-link>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, handles_uploads } from "@/nibnut/mixins"
import { addl_misc_utilities } from "@/custom/mixins"

import {
    ModalDialog,
    BaseForm,
    BaseLink,
    FormInput,
    FormSelect,
    FormDropdown,
    FormDateInput,
    FormTextbox,
    FormToggleInput,
    UploadInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    FormAcceloCustomField
} from "@/custom/components"
import { VueSignaturePad } from "vue-signature-pad"

export default {
    mixins: [is_record_editor_page, handles_uploads, addl_misc_utilities],
    components: {
        ModalDialog,
        BaseForm,
        BaseLink,
        FormInput,
        FormSelect,
        FormDropdown,
        FormDateInput,
        FormTextbox,
        FormToggleInput,
        UploadInput,
        DefaultButton,
        OpenIcon,
        FormAcceloCustomField,
        VueSignaturePad
    },
    mounted () {
        this.refresh_signature_pad_ui()
    },
    watch: {
        "edited_record.type_id": "recompute_type_related_values"
    },
    methods: {
        refresh_signature_pad_ui () {
            if(this.$refs.signature_pad) {
                this.$refs.signature_pad.clearSignature()
                const field = this.current_signature_field
                if(!!this.edited_record && !!this.edited_record[field]) this.$refs.signature_pad.fromDataURL(this.edited_record[field])
                if(!this.can_be_signed) this.$refs.signature_pad.lockSignaturePad()
                else this.$refs.signature_pad.openSignaturePad()
            }
        },
        grab_record_id () {
            const int_record_id = parseInt(this.$route.params.id)
            this.record_id = (`${int_record_id}` === this.$route.params.id) ? int_record_id : this.$route.params.id

            this.load_vocabularies([
                this.constants("vocabularies", "VOCABULARY_SRO_TYPE").id,
                this.constants("vocabularies", "VOCABULARY_SRO_CLASS").id,
                this.constants("vocabularies", "VOCABULARY_SRO_PRIORITY").id,
                this.constants("vocabularies", "VOCABULARY_SRO_STATUS").id
            ])
            this.current_step = 1
        },
        post_load () {
            if(!!this.$route.params.print_on_load && !!this.$refs.print_label_button) {
                if(navigator.appVersion && navigator.appVersion.match(/Safari/) && !navigator.appVersion.match(/Chrome/)) this.print_prompt = true
                else this.$refs.print_label_button.$el.click()
            }
        },
        step_by (delta) {
            this.current_step += delta
            if(this.current_step < 1) this.current_step = 1
            else if(this.current_step > 2) this.current_step = 2
            setTimeout(() => {
                this.refresh_signature_pad_ui()
            }, 100)
        },
        editable (field_id) {
            const edited_record = this.edited_record
            /*
            if(edited_record) {
                if(!edited_record.id || this.saving(field_id)) return true
                if(edited_record.id) {
                    const custom_field_definition = this.custom_field_definition(field_id)
                    if(!!custom_field_definition && !custom_field_definition.checkout) return false
                    if(edited_record[field_id] !== undefined) return !edited_record[field_id]
                    return (!!custom_field_definition && !!custom_field_definition.checkout) || !edited_record.custom_fields || (edited_record.custom_fields[field_id] === undefined) || !edited_record.custom_fields[field_id].value
                }
            }
            */
            return !!edited_record && !edited_record.id
        },
        recompute_type_related_values () {
            const edited_record = this.edited_record
            if(this.waiting || !edited_record) return
            const current_type = this.current_type
            if(!current_type) {
                edited_record.class = 0
                edited_record.issue_priority = 0
            } else {
                if(current_type && current_type.settings) {
                    if(current_type.settings.accelo_class) edited_record.class = current_type.settings.accelo_class
                    if(current_type.settings.accelo_issue_priority) edited_record.issue_priority = current_type.settings.accelo_issue_priority
                }
            }
            //  *********** if(!!edited_record && !!edited_record.id) this.save_data_for_record_id(this.entity, edited_record.id, { class: edited_record.class, issue_priority: edited_record.issue_priority })
        },
        custom_field_definition (field_id) {
            const services = this.setting("services")
            if(!!services && !!services.accelo && !!services.accelo.fields) return services.accelo.fields[field_id]
            return null
        },
        custom_field_value (field_id) {
            const edited_record = this.edited_record
            if(edited_record) {
                if(!!edited_record.custom_fields && (edited_record.custom_fields[field_id] !== undefined)) return edited_record.custom_fields[field_id].value
                const custom_field_definition = this.custom_field_definition(field_id)
                if(custom_field_definition) {
                    if((custom_field_definition.type === "multi_select") && !!custom_field_definition.default_value && !Array.isArray(custom_field_definition.default_value)) return [custom_field_definition.default_value]
                    return custom_field_definition.default_value
                }
            }
            return null
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(pdf|png|jpe?g|svg)$/i)
            return "image/jpeg,image/png,image/svg,application/pdf,.jpg,.jpeg,.png,.svg,.pdf"
        },
        upload_held (name, file) {
            const id = "" + (new Date()).getTime()
            this.$set(
                this.held_attachments,
                id,
                {
                    id,
                    name: file.name,
                    mime_type: file.type,
                    url: null,
                    file
                }
            )
            if(file.type.match(/(png|jpe?g|svg)$/i)) this.held_attachments[id].url = URL.createObjectURL(this.held[name])
        },
        upload_data (mode) {
            return { entity: this.entity, id: this.record_id, relation_ids: this.relation_ids }
        },
        attachment_image (attachment) {
            return !!attachment && !!attachment.mime_type && !!attachment.mime_type.match(/(png|jpe?g|svg)/i)
        },
        attachment_icon (attachment) {
            if(!!attachment && !!attachment.mime_type) {
                if(attachment.mime_type.match(/pdf/i)) return "file-pdf"
            }
            return "file"
        },
        clear_attachment (attachment) {
            if(this.holding_mode) {
                if(attachment.url) URL.revokeObjectURL(attachment.url)
                this.$delete(this.held_attachments, attachment.id, null)
            }
        },
        save_signature () {
            if(this.$refs.signature_pad) {
                const { is_empty, data } = this.$refs.signature_pad.saveSignature()
                if(this.current_signature_field === "signature_out") this.edited_record.signature_out = (is_empty ? null : data)
                else this.save(is_empty ? null : data, this.current_signature_field)
            }
        },
        clear_signature () {
            if(this.$refs.signature_pad) {
                this.$refs.signature_pad.clearSignature()
                this.save(null, this.current_signature_field)
            }
        },
        save (value, field) {
            if(this.edited_record) {
                const matches = field.match(/^(profiles|extensions)\/(\d+)$/i)
                if(matches) {
                    const custom_fields = this.edited_record.id ? {} : { ...this.edited_record.custom_fields }
                    if(!custom_fields[field] || (custom_fields[field].value !== value)) {
                        if(!custom_fields[field]) custom_fields[field] = { id: 0, identifier: field, source: matches[1], field_id: parseInt(matches[2]), value }
                        else custom_fields[field].value = value
                    }
                    if(this.edited_record.id) {
                        this.saving_field_ids.push(field)
                        return this.save_field(custom_fields, "custom_fields").then(() => {
                            const index = this.saving_field_ids.indexOf(field)
                            if(index >= 0) this.saving_field_ids.splice(index, 1)
                        })
                    } else this.edited_record.custom_fields = custom_fields
                } else {
                    if(this.edited_record[field] !== value) this.edited_record[field] = value
                    if(this.edited_record.id) return this.save_field(this.edited_record[field], field)
                }
            }
            return Promise.resolve()
        },
        record_save () {
            const data = {
                ...this.edited_record,
                attachments: Object.values(this.held_attachments).map(attachment => attachment.file),
                print: false // this.print_on_create
            }
            const is_new = !data.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, data.id || 0, data).then(record => {
                if(record) {
                    this.creating_record = false
                    this.shell_record = null
                    this.record_id = record.uuid
                    this.$router.replace({ name: `${this.entity}.edit`, params: { id: record.uuid || record.id, print_on_load: this.print_on_create } })
                }
            })
        },
        checkout () {
            this.checking_out = true
            return this.save_data_for_record_id(this.entity, this.edited_record.uuid, { signature_out: this.edited_record.signature_out }).then(() => {
                this.checking_out = false
                this.$router.go({ name: `${this.entity}.list` })
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.translate("Do you really want to delete this SRO? It will also be deleted in Accelo. There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.push({ name: "service_repair_order.list" })
        }
    },
    computed: {
        default_back () {
            return { title: this.translate(this.entityName, {}, 2), href: { name: "service_repair_order.list" } }
        },
        page_title () {
            const record = this.edited_record
            if(!record || !record.id) return this.translate("New SRO")
            return `SRO #${record.remote_id}`
        },
        holding_mode () {
            const edited_record = this.edited_record
            return !edited_record || !edited_record.id
        },
        attachments () {
            const edited_record = this.edited_record
            if(this.holding_mode) return this.held_attachments
            return this.entity_records("attachment").filter(attachment => {
                return (attachment.owner_type === "App\\ServiceRepairOrder") && (attachment.owner_id === edited_record.id)
            })
        },
        custom_fields () {
            if(!!this.current_type && !!this.current_type.settings && !!this.current_type.settings.fields) {
                if(this.record_id) return this.current_type.settings.fields
                return this.current_type.settings.fields.filter(field_id => {
                    const custom_field_definition = this.custom_field_definition(field_id)
                    return !!custom_field_definition && custom_field_definition.checkin
                })
            }
            return []
        },
        current_type () {
            const edited_record = this.edited_record
            if(!!edited_record && !!edited_record.type_id) {
                return this.term_by_id(edited_record.type_id)
            }
            return null
        },
        current_signature_field () {
            const edited_record = this.edited_record
            if(this.can_be_signed && !!edited_record.id) return "signature_out"
            return "signature_in"
        },
        can_be_signed () {
            return !this.record_id || (["diagnosticrepair", "donation"].indexOf(this.current_type.slug) >= 0)
        },
        is_saveable () {
            const edited_record = this.edited_record
            if(!edited_record || !!edited_record.id) return true

            let is_saveable = true
            const services = this.setting("services")
            if(!!services && !!services.accelo && !!services.accelo.fields) {
                this.custom_fields.forEach(identifier => {
                    const field_definition = services.accelo.fields[identifier]
                    const value = this.custom_field_value(identifier)
                    if(!!field_definition.required && !value) is_saveable = false
                })
            }
            return (
                !!edited_record.title &&
                !!edited_record.company &&
                !!edited_record.type_id &&
                !!edited_record.status_id &&
                !!edited_record.assignee &&
                !!edited_record[this.current_signature_field] &&
                is_saveable
            )
        }
    },
    data () {
        return {
            entity: "service_repair_order",
            entityName: "SRO:::SRO",
            relation_ids: ["attachment"],
            current_step: 1,
            checking_out: false,
            print_on_create: true,
            held_attachments: {},
            print_prompt: false
        }
    }
}
</script>

<style lang="scss">
.nibnut.service_repair_order-edit {
    main {
        display: flex;
        flex-direction: column;
        & > div, #content {
            height: 100%;
        }
        #sro-editor {
            height: 100%;
            display: flex;
            flex-direction: column;
            form {
                flex: 1 0 auto;
                display: flex;
                flex-direction: column;

                #terms-and-conditions {
                    flex: 1 0 auto;
                    & > div:first-child {
                        max-height: 40vh;
                        overflow: auto;
                    }
                }
            }
            .nibnut-document-uploader {
                max-height: 6.5rem;
                .empty {
                    padding: 0.4rem;
                }
            }
            .attachments {
                a.nibnut-aspect-ratio-box {
                    display: block;
                }
                .card {
                    height: 100%;
                    & > .card-body {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
                .nibnut-hoverable {
                    position: relative;
                    button.btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
            img.signature {
                height: 3em;
            }
        }
    }
}
</style>
